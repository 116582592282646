import { Reducer } from 'redux'
import { LabActionTypes, LabState } from './types'

export const initialState: LabState = {
  lab: undefined,
  labs: undefined,
  errors: undefined,
  loading: false,
}

const reducer: Reducer<LabState> = (state = initialState, action) => {
  switch (action.type) {
    case LabActionTypes.FETCH_LAB_REQUEST: {
      return { ...state, loading: true }
    }
    case LabActionTypes.FETCH_LAB_SUCCESS: {
      return {
        ...state,
        loading: false,
        lab: action.payload,
        errors: undefined,
      }
    }
    case LabActionTypes.FETCH_LAB_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case LabActionTypes.FETCH_LABS_REQUEST: {
      return { ...state, loading: true }
    }
    case LabActionTypes.FETCH_LABS_SUCCESS: {
      return {
        ...state,
        loading: false,
        labs: action.payload,
        errors: undefined,
      }
    }
    case LabActionTypes.FETCH_LABS_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    case LabActionTypes.UPDATE_LAB_REQUEST: {
      return { ...state, loading: true }
    }
    case LabActionTypes.UPDATE_LAB_SUCCESS: {
      return {
        ...state,
        loading: false,
        lab: action.payload,
        errors: undefined,
      }
    }
    case LabActionTypes.UPDATE_LAB_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }
    default: {
      return state
    }
  }
}

export { reducer as LabReducer }
