import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ApplicationState } from '../../store/index'
import { SeverityType } from '../../store/snackbar/types'
import { openSnackBar } from '../../store/snackbar/action'
import { ConsumableState } from '../../store/consumable/types'
import { restoreConsumableById } from '../../store/consumable/action'

interface Props {
  consumableId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    restoreButton: {
      color: '#fff',
      backgroundColor: '#f6b347',
      '&:hover': {
        color: '#c78100',
        backgroundColor: '#f3a01c',
      },
    },
    icon: {
      color: '#fff',
    },
  })
)

export default function RestoreInventoryItemDialog(props: Props): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const consumableState = useSelector<ApplicationState, ConsumableState>(
    (state) => state.consumables
  )

  const restoreConsumable = (consumableId: number) => {
    dispatch(restoreConsumableById(consumableId))

    if (consumableState.errors) {
      openSnackBar(
        dispatch,
        `Error attempting to restore consumable: ${consumableId}`,
        SeverityType.ERROR
      )
    } else {
      openSnackBar(
        dispatch,
        `Successfully restored consumable`,
        SeverityType.SUCCESS
      )
    }
  }

  const handleConfirm = (id: number) => {
    restoreConsumable(id)
    setOpen(false)
  }

  const handleCancel = () => {
    openSnackBar(dispatch, `Restoration canceled`, SeverityType.INFO)
    setOpen(false)
  }

  const modalOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Box mr={1}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          className={classes.restoreButton}
          startIcon={<RestoreFromTrashIcon />}
          onClick={modalOpen}
        >
          Restore Item
        </Button>
      </Box>

      <div>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="update-device-form-dialog"
        >
          <Box p={2}>
            <DialogTitle id="form-dialog-title">
              Confirm Restored Item
            </DialogTitle>
            <DialogContentText>
              Are you sure you want to restore this item?
            </DialogContentText>
            <DialogActions>
              <Button color="primary" onClick={handleCancel}>
                No
              </Button>
              <Button
                color="primary"
                onClick={() => handleConfirm(props.consumableId)}
              >
                Yes
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  )
}
