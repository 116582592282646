import { ActionCreator, Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { SeverityType } from 'store/snackbar/types'
import { openSnackBar } from 'store/snackbar/action'
import { LabActionTypes } from './types'
import Lab from '../../models/Lab'
import ApiResponseError from '../../exceptions/ApiResponseError'
import { httpGetRequest, httpPostRequest } from '../../utils/HttpUtil'
import { ApplicationState } from '../index'

const API_URL = `${process.env.REACT_APP_API_URL}`

export type AppThunk = ThunkAction<
  void,
  ApplicationState,
  unknown,
  Action<string>
>

export const fetchLab: ActionCreator<AppThunk> = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: LabActionTypes.FETCH_LAB_REQUEST })

    try {
      const response = await httpGetRequest<Lab>(
        `${API_URL}/api/v1/user/default-lab`
      )
      const labData = response.data

      if (!labData) {
        throw new Error('Lab data not found')
      }

      return dispatch({
        type: LabActionTypes.FETCH_LAB_SUCCESS,
        payload: labData,
      })
    } catch (error) {
      const apiError = error as ApiResponseError
      return dispatch({
        type: LabActionTypes.FETCH_LAB_ERROR,
        payload: JSON.stringify(apiError),
      })
    }
  }
}

export const fetchLabs: ActionCreator<AppThunk> = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: LabActionTypes.FETCH_LABS_REQUEST })

    try {
      const response = await httpGetRequest<Lab[]>(
        `${API_URL}/api/v1/user/labs`
      )
      const labData = response.data

      if (!labData) {
        throw new Error('Lab data not found')
      }

      return dispatch({
        type: LabActionTypes.FETCH_LABS_SUCCESS,
        payload: labData,
      })
    } catch (error) {
      const apiError = error as ApiResponseError
      return dispatch({
        type: LabActionTypes.FETCH_LABS_ERROR,
        payload: JSON.stringify(apiError),
      })
    }
  }
}

export const updateDefaultLab: ActionCreator<AppThunk> = (
  selectedLabId: number
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LabActionTypes.FETCH_LAB_REQUEST })

    try {
      await httpPostRequest(
        `${API_URL}/api/v1/user/default-lab?labId=${selectedLabId}`,
        {}
      )

      dispatch({
        type: LabActionTypes.UPDATE_LAB_REQUEST,
        payload: selectedLabId,
      })

      openSnackBar(
        dispatch,
        'Workspace updated successfully',
        SeverityType.SUCCESS
      )
    } catch (error) {
      openSnackBar(dispatch, 'Workspace did not update', SeverityType.ERROR)
      dispatch({ type: LabActionTypes.FETCH_LAB_ERROR, error })
    }
  }
}

export const updateLabName: ActionCreator<AppThunk> = (
  selectedLabId: number,
  newLabName: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LabActionTypes.UPDATE_LAB_REQUEST })

    try {
      const response = await httpPostRequest(
        `${API_URL}/api/v1/lab/update-name?labId=${selectedLabId}&newName=${newLabName}`,
        {}
      )

      const updatedLab = response.data

      dispatch({
        type: LabActionTypes.UPDATE_LAB_SUCCESS,
        payload: updatedLab,
      })

      openSnackBar(
        dispatch,
        'Workspace name updated successfully',
        SeverityType.SUCCESS
      )
    } catch (error) {
      const apiError = error as ApiResponseError
      openSnackBar(
        dispatch,
        'Failed to update workspace name',
        SeverityType.ERROR
      )

      dispatch({
        type: LabActionTypes.UPDATE_LAB_ERROR,
        payload: JSON.stringify(apiError),
      })
    }
  }
}
