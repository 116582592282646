import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ApplicationState } from '../../store/index'
import { SeverityType } from '../../store/snackbar/types'
import { openSnackBar } from '../../store/snackbar/action'
import { ConsumableState } from '../../store/consumable/types'
import { depleteConsumableById } from '../../store/consumable/action'

interface Props {
  consumableId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: theme.palette.getContrastText(theme.palette.error.main),
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        color: '#d96d69',
        backgroundColor: '#ca2d27',
      },
    },
    icon: {
      color: '#fff',
    },
  })
)

export default function DeleteInventoryItemDialog(props: Props): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const consumableState = useSelector<ApplicationState, ConsumableState>(
    (state) => state.consumables
  )

  const depleteConsumable = (consumableId: number) => {
    dispatch(depleteConsumableById(consumableId))

    if (consumableState.errors) {
      openSnackBar(
        dispatch,
        `Error attempting to deplete consumable: ${consumableId}`,
        SeverityType.ERROR
      )
    } else {
      openSnackBar(
        dispatch,
        `Successfully depleted consumable`,
        SeverityType.SUCCESS
      )
    }
  }

  const handleConfirm = (id: number) => {
    depleteConsumable(id)
    setOpen(false)
  }

  const handleCancel = () => {
    openSnackBar(dispatch, `Depletion canceled`, SeverityType.INFO)
    setOpen(false)
  }

  const modalOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Box mr={1}>
        <Button
          fullWidth
          color="primary"
          className={classes.deleteButton}
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={modalOpen}
        >
          Deplete Item
        </Button>
      </Box>

      <div>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="update-device-form-dialog"
        >
          <Box p={2}>
            <DialogTitle id="form-dialog-title">
              Confirm Depleted Item
            </DialogTitle>
            <DialogContentText>
              Are you sure you want to deplete this item?
            </DialogContentText>
            <DialogActions>
              <Button color="primary" onClick={handleCancel}>
                No
              </Button>
              <Button
                color="primary"
                onClick={() => handleConfirm(props.consumableId)}
              >
                Yes
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  )
}
