import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { fetchLab, updateDefaultLab } from 'store/lab/action'
import { ApplicationState } from '../../store'
import Lab from '../../models/Lab'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const LabMenu: React.FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useDispatch()

  const lab = useSelector((state: ApplicationState) => state.lab.lab)
  const labs = useSelector((state: ApplicationState) => state.lab.labs)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLabSwitch = async (labId: number) => {
    handleClose()
    if (labId !== lab?.labId) {
      await dispatch(updateDefaultLab(labId))
      await dispatch(fetchLab())
    }
  }

  const hasMultipleLabs = labs && labs.length > 1

  return (
    <div>
      <Button
        aria-controls="lab-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={hasMultipleLabs ? handleOpen : undefined}
      >
        {lab ? lab.name : 'Lab Not Found'}
      </Button>
      {hasMultipleLabs && (
        <StyledMenu
          id="lab-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>Workspaces</MenuItem>
          {labs?.map((recentLab) => (
            <MenuItem
              key={recentLab.labId}
              onClick={() => handleLabSwitch(recentLab.labId)}
              selected={recentLab.labId === lab?.labId}
            >
              {recentLab.name}
            </MenuItem>
          ))}
          <Divider />
          <Button
            fullWidth
            href="/portal/account/settings/labspace"
            color="primary"
          >
            Manage Workspaces
          </Button>
        </StyledMenu>
      )}
    </div>
  )
}

export default LabMenu
