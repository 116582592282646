import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { createStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core'
import RestoreInventoryItem from './inventory-item-restore.component'
import DepleteInventoryItem from './inventory-item-deplete.component'
import Reading from '../../models/Reading'
import Consumable from '../../models/Consumable'
import { convertUTCToLocalDatetime } from '../../utils/UtcConversionUtil'

const styles = (theme: Theme) =>
  createStyles({
    editButton: {
      color: 'primary',
    },
    avatar: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: theme.spacing(0, 0),
    },
    icon: {
      color: '#fff',
    },
  })

interface Props extends RouteComponentProps {
  classes: { [key: string]: string }
  handleClose: () => void
  handleUnmountedVal: () => void
  readings: Reading[]
  consumable: Consumable | undefined
  show: boolean
  isDepleted?: boolean
}

class InventoryHistoryDialog extends Component<Props> {
  private unlisten: (() => void) | undefined

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.props.handleClose()
    })
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten()
    }
    this.props.handleUnmountedVal()
  }

  render() {
    const { classes, consumable, show, readings, isDepleted, handleClose } =
      this.props
    const fullWidth = true

    return (
      <Dialog
        fullWidth={fullWidth}
        maxWidth="sm"
        open={show}
        onClose={handleClose}
        aria-labelledby="add-consumable-dialog"
      >
        <DialogTitle id="view-readings-dialog">Readings</DialogTitle>
        <DialogContentText>
          <Box ml={3}>
            <Grid container direction="row">
              <Grid item xs={6}>
                Item Name: {consumable?.itemName ? consumable.itemName : 'None'}
              </Grid>
              <Grid item xs={6}>
                Tag Id:
                {consumable?.tag?.id ? ` T${consumable.tag.id}` : ' None'}
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
        <DialogContent>
          <Grid container direction="row" justify="center">
            <Grid item xs={6}>
              <Box mr={1}>
                <Button
                  fullWidth
                  color="primary"
                  className={classes.editButton}
                  variant="contained"
                  href={`/portal/inventory-items/${consumable?.id}`}
                  startIcon={<EditIcon />}
                >
                  Edit Item
                </Button>
              </Box>
            </Grid>
            {!isDepleted && (
              <Grid item xs={6}>
                <DepleteInventoryItem
                  consumableId={consumable ? consumable.id : 0}
                />
              </Grid>
            )}
            {isDepleted && (
              <Grid item xs={6}>
                <RestoreInventoryItem
                  consumableId={consumable ? consumable.id : 0}
                />
              </Grid>
            )}
          </Grid>
          <Box m={2}>
            {readings.length === 0 &&
              'No readings have been recorded for this item.'}
            {readings.length !== 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Gross Weight</TableCell>
                    <TableCell>CreatedAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {readings.map((reading: Reading) => (
                    <TableRow key={reading.readingId}>
                      <TableCell>{reading.weight}</TableCell>
                      <TableCell>
                        {convertUTCToLocalDatetime(
                          reading.createdAt.toString()
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(withStyles(styles)(InventoryHistoryDialog))
