import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { SeverityType } from 'store/snackbar/types'
import { updateLabName, fetchLab } from '../../store/lab/action'
import { openSnackBar } from '../../store/snackbar/action'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
)

interface EditWorkspaceDialogProps {
  workspaceId: number
  currentName: string
}

export default function EditWorkspaceDialog({
  workspaceId,
  currentName,
}: EditWorkspaceDialogProps): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const [workspaceName, setWorkspaceName] = React.useState(currentName)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validateAndFilterName = (name: string): string => {
    const allowedCharacters = (char: string) =>
      (char >= 'a' && char <= 'z') ||
      (char >= 'A' && char <= 'Z') ||
      (char >= '0' && char <= '9') ||
      char === ' ' ||
      char === '+' ||
      char === '=' ||
      char === '-' ||
      char === '_' ||
      char === '%' ||
      char === '(' ||
      char === ')' ||
      char === '[' ||
      char === ']' ||
      char === '{' ||
      char === '}' ||
      char === '@' ||
      char === '.'

    return name
      .split('')
      .filter((char) => allowedCharacters(char))
      .slice(0, 50)
      .join('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = validateAndFilterName(event.target.value)
    setWorkspaceName(newValue)
  }

  const handleSubmit = async () => {
    const trimmedName = validateAndFilterName(workspaceName.trim())

    if (trimmedName.length > 0) {
      try {
        await dispatch(updateLabName(workspaceId, trimmedName))
        await dispatch(fetchLab())
        setOpen(false)
      } catch (error) {
        openSnackBar(
          dispatch,
          'Failed to update workspace name',
          SeverityType.ERROR
        )
      }
    } else {
      openSnackBar(
        dispatch,
        'Invalid characters in workspace name',
        SeverityType.ERROR
      )
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Workspace Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new name below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="workspaceName"
            label="New Workspace Name"
            type="text"
            value={workspaceName}
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
