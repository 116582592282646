import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { ApplicationState } from '../../store/index'
import {
  fetchLab as fetchLabAction,
  fetchLabs as fetchLabsAction,
} from '../../store/lab/action'
import Home from './home.component'

type SelectProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = SelectProps & DispatchProps

type MyRootState = ApplicationState
type MyExtraArg = unknown // Updated to unknown for consistency
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, AnyAction>

interface State {
  getLab: boolean
}

class HomeContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      getLab: false,
    }
  }

  componentDidMount() {
    const { fetchLab, fetchLabs } = this.props
    fetchLab()
    fetchLabs()
  }

  setFetchLab = () => {
    this.setState((prevState) => ({ ...prevState, getLab: true }))
  }

  render() {
    const { loading } = this.props
    if (loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      )
    }

    return <Home />
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.lab.loading,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  fetchLab: () => dispatch(fetchLabAction()),
  fetchLabs: () => dispatch(fetchLabsAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
