import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { httpGetRequest } from '../../utils/HttpUtil'
import User from '../../models/User'
import Lab from '../../models/Lab'
import { fetchLab, updateDefaultLab } from '../../store/lab/action'
import SendLabInvitationDialog from './send-lab-invitation-dialog.component'
import EditWorkspaceDialog from './edit-workspace-dialog.component'

const API_URL = `${process.env.REACT_APP_API_URL}`

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    grid: {
      padding: theme.spacing(1),
    },
    root: {
      width: '100%',
      alignItems: 'left',
    },
    table: {
      minWidth: 'auto',
    },
    title: {
      flex: '1 1 10%',
    },
    labMenu: {
      height: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

export default function ManageLabSpace({ lab }: { lab?: Lab }): JSX.Element {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [users, setUsers] = useState<User[]>([])
  const [labs, setLabs] = useState<Lab[]>([])
  const [loadingUsers, setLoadingUsers] = useState(false)

  const fetchAllUsers = async () => {
    setLoadingUsers(true)
    try {
      const { data: usersData } = await httpGetRequest<User[]>(
        `${API_URL}/api/v1/lab/users`
      )
      setUsers(usersData)
    } catch (e) {
      console.error('Error fetching users:', e)
    } finally {
      setLoadingUsers(false)
    }
  }

  const fetchUserLabs = async () => {
    try {
      const { data: labsData } = await httpGetRequest<Lab[]>(
        `${API_URL}/api/v1/user/labs`
      )
      setLabs(labsData)
    } catch (e) {
      console.error('Error fetching labs:', e)
    }
  }

  useEffect(() => {
    fetchAllUsers()
    fetchUserLabs()
  }, [])

  const handleSwitchLab = async (labId: number) => {
    try {
      await dispatch(updateDefaultLab(labId))
      await dispatch(fetchLab())
      await fetchAllUsers() // Re-fetch users after switching labs
    } catch (e) {
      console.error('Error switching labs:', e)
    }
  }

  const currentLabId = lab?.labId

  let userContent
  if (loadingUsers) {
    userContent = (
      <Typography variant="body1" color="textSecondary">
        Loading users...
      </Typography>
    )
  } else if (users.length > 0) {
    userContent = (
      <Table className={classes.table} aria-label="user management table">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: User) => (
            <TableRow key={user.userId}>
              <TableCell>{user.username}</TableCell>
              <TableCell>
                <FormControl className={classes.formControl}>
                  <Select
                    value={user.userRoles[0].roleType.roleName}
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="ROLE_ADMIN">ROLE_ADMIN</MenuItem>
                    <MenuItem value="ROLE_USER">ROLE_USER</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  } else {
    userContent = (
      <Typography variant="body1" color="textSecondary">
        No users found.
      </Typography>
    )
  }

  return (
    <Container>
      <Grid
        className={classes.grid}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Typography
            component="h2"
            variant="inherit"
            color="textPrimary"
            gutterBottom
          >
            Manage Lab Space
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid xs={8}>
          <TableContainer component={Paper}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Workspaces
              </Typography>
            </Toolbar>
            <Table
              className={classes.table}
              aria-label="workspace management table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Workspace Name</TableCell>
                  <TableCell>Edit Workspace</TableCell>
                  <TableCell>Switch Lab</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {labs.map((lab: Lab) => (
                  <TableRow key={lab.labId}>
                    <TableCell>{lab.name}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit Workspace">
                        <EditWorkspaceDialog
                          workspaceId={lab.labId}
                          currentName={lab.name}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {lab.labId !== currentLabId ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSwitchLab(lab.labId)}
                        >
                          Switch to this Lab
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="default"
                          style={{
                            backgroundColor: 'green',
                            opacity: 0.6,
                            color: 'white',
                          }}
                        >
                          Current Lab
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <br />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid xs={8}>
          <TableContainer component={Paper}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Members
              </Typography>
              <Tooltip title="Invite User">
                <SendLabInvitationDialog />
              </Tooltip>
            </Toolbar>
            {userContent}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )
}
